<template>
  <v-container
    fluid
    class="ma-0 mt-0 pa-0 pt-10 px-10"
    style="align-items: center"
  >
    <v-app-bar app color="white">
      <v-row align="center" justify="space-between" class="ma-0 pa-0">
        <h2 class="font-weight-light">{{ user.data.email }}</h2>
        <v-btn @click="signOut" text class="ma-0 pa-0">Déconnexion</v-btn>
      </v-row>
    </v-app-bar>
    <v-navigation-drawer permanent app width="320">
      <v-col class="ma-0 pa-0 fixed">
        <v-row align="center" class="ma-0 pa-0 py-2" justify="space-between">
          <v-col class="ma-0 pa-0">
            <v-card
              @click="addPublication()"
              class="ma-0 pa-0 px-3"
              :loading="addLoading"
            >
              <v-row align="center" justify="start" class="ma-0 pa-0 py-3">
                <v-icon class="ma-0 pa-0">mdi-plus</v-icon>
                <p class="ma-0 pa-0 pl-2">Ajouter une publication</p>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <dashboard-publication-item
          v-for="(publication, i) in publications"
          :key="i"
          :publication="publication.data"
          :index="i"
          :current="i == current"
          :selectPublication="selectPublication"
        ></dashboard-publication-item>
      </v-col>
    </v-navigation-drawer>
    <v-main v-if="publications[current]">
      <v-row class="ma-0 pa-0">
        <v-col cols="8" class="ma-0 pa-0">
          <v-row class="ma-0 pa-0">
            <v-col cols="1" class="ma-0 pa-0">
              <v-text-field
                class="ma-0 pa-0"
                label="Numéro"
                v-model="publications[current].data.nb"
              ></v-text-field>
            </v-col>
            <v-col cols="9" class="ma-0 pa-0">
              <v-text-field
                v-model="publications[current].data.title"
                class="ma-0 ml-6 pa-0"
                label="Titre de la publication"
              ></v-text-field>
            </v-col>
            <v-col cols="9" class="ma-0 pa-0">
              <v-text-field
                v-model="publications[current].data.availabilityDate"
                class="ma-0 pa-0"
                label="Disponible le"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-0">
            <v-checkbox
              v-model="publications[current].data.hidden"
              hide-details
              class="ma-0 mb-4 pa-0"
              label="Cacher la publication"
            ></v-checkbox>
            <v-checkbox
              v-model="publications[current].data.grey"
              hide-details
              class="ma-0 ml-3 mb-4 pa-0"
              label="Griser la publication"
            ></v-checkbox>
          </v-row>
          <v-row class="ma-0 mt-2 pa-0">
            <v-col cols="5" class="ma-0 pa-0">
              <v-text-field
                class="ma-0 pa-0"
                label="Propriétaire"
                v-model="publications[current].data.owner"
              ></v-text-field>
            </v-col>
            <v-col cols="5" class="ma-0 pa-0 pl-6">
              <v-text-field
                class="ma-0 pa-0"
                label="Email"
                v-model="publications[current].data.email"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-0">
            <v-col cols="5" class="ma-0 pa-0">
              <v-text-field
                class="ma-0 pa-0"
                label="Tél. 1"
                v-model="publications[current].data.phone1"
              ></v-text-field>
            </v-col>
            <v-col cols="5" class="ma-0 pa-0 pl-6">
              <v-text-field
                class="ma-0 pa-0"
                label="Tél. 2"
                v-model="publications[current].data.phone2"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-0">
            <v-col cols="10" class="ma-0 pa-0">
              <v-text-field
                class="ma-0 pa-0"
                label="Infos contact"
                v-model="publications[current].data.infos"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-0">
            <v-col cols="4" class="ma-0 pa-0">
              <v-row class="ma-0 pa-0">
                <v-col class="ma-0 pa-0">
                  <v-text-field
                    class="ma-0 pa-0"
                    append-icon="mdi-currency-eur"
                    label="Loyer charges comprises"
                    v-model="publications[current].data.rent"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="ma-0 pa-0">
                <v-col class="ma-0 pa-0">
                  <v-text-field
                    class="ma-0 pa-0"
                    append-icon="mdi-currency-eur"
                    label="Frais de co-propriété"
                    v-model="publications[current].data.coOwnershipFees"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4" class="ma-0 ml-8 pa-0">
              <v-row class="ma-0 pa-0">
                <v-col class="ma-0 pa-0">
                  <v-checkbox
                    hide-details
                    class="ma-0 mb-4 pa-0"
                    label="Eau comprise"
                    v-model="publications[current].data.waterChargesIncluded"
                  ></v-checkbox>
                  <v-checkbox
                    hide-details
                    class="ma-0 mb-4 pa-0"
                    label="Électricité comprise"
                    v-model="
                      publications[current].data.electricityChargesIncluded
                    "
                  ></v-checkbox>
                  <v-checkbox
                    hide-details
                    class="ma-0 mb-4 pa-0"
                    label="Chauffage compris"
                    v-model="publications[current].data.heatChargesIncluded"
                  ></v-checkbox>
                  <v-checkbox
                    hide-details
                    class="ma-0 pa-0"
                    label="Wifi compris"
                    v-model="publications[current].data.wifiChargesIncluded"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-0">
            <v-checkbox
              hide-details
              class="ma-0 pa-0"
              label="Meublé"
              v-model="publications[current].data.furnished"
            ></v-checkbox>
            <v-checkbox
              hide-details
              class="ma-0 ml-6 pa-0"
              label="Internet"
              v-model="publications[current].data.internet"
            ></v-checkbox>
          </v-row>
          <v-row class="ma-0 mt-2 pa-0">
            <v-col cols="10" class="ma-0 pa-0">
              <v-textarea
                label="message"
                multi-line
                v-model="publications[current].data.description"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="ma-0 mt-3 pa-0">
            <v-btn
              @click="updatePublication"
              :loading="updateLoading"
              class="success"
              >Mettre à jour</v-btn
            >
            <v-dialog v-model="deleteDialog" persistent max-width="600px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" class="error ml-6"
                  >Supprimer</v-btn
                >
              </template>
              <v-card class="elevation-12">
                <v-toolbar dark color="primary" flat>
                  <v-toolbar-title>Supprimer la publication</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-container class="pa-5">
                  <v-row justify="center" class="ma-0 pa-0">
                    <p class="ma-0 pa-0">
                      Êtes-vous certain de vouloir supprimer cette publication ?
                    </p>
                  </v-row>
                  <v-sheet height="64px"></v-sheet>
                  <v-container class="ma-0 pa-0">
                    <v-row justify="end" class="ma-0 pa-0">
                      <v-btn
                        class="body-2 ma-0 mx-5 pa"
                        @click="deleteDialog = false"
                        >fermer</v-btn
                      >
                      <v-btn
                        @click="deletePublication"
                        class="primary"
                        :loading="deleteLoading"
                        >Confirmer</v-btn
                      >
                    </v-row>
                  </v-container>
                </v-container>
              </v-card>
            </v-dialog>
          </v-row>
        </v-col>
        <v-col cols="4" class="ma-0 pa-0">
          <v-row
            v-for="(img, i) in this.localImages"
            :key="i"
            class="ma-0 mb-4 pa-0"
          >
            <v-card class="mx-auto" width="100%">
              <v-img
                width="100%"
                aspect-ratio="1.8"
                cover
                :src="img.url"
              ></v-img>
              <v-btn
                fab
                x-small
                @click="deleteImg(i)"
                class="ma-3 pa-0"
                style="position: absolute; top: 0; right: 0"
                ><v-icon class="mx-0 px-0">mdi-close</v-icon></v-btn
              >
            </v-card>
          </v-row>
          <v-btn :loading="imageLoading" @click="$refs.uploader.click()" block
            >Ajouter une image</v-btn
          >
          <input
            ref="uploader"
            class="d-none"
            multiple
            type="file"
            @change="onFileChanged"
          />
        </v-col>
      </v-row>
      <v-sheet height="32px"></v-sheet>
    </v-main>
  </v-container>
</template>

<script>
import firebase from "firebase";
import { mapGetters } from "vuex";
import router from "@/router";
import { AuthenticationServices } from "@/services/authenticationServices";
import { DashboardServices } from "@/services/dashboardServices";
import DashboardPublicationItem from "@/components/DashboardPublicationItem.vue";

export default {
  components: {
    "dashboard-publication-item": DashboardPublicationItem,
  },
  data() {
    return {
      addLoading: false,
      deleteDialog: false,
      deleteLoading: false,
      publications: [],
      updateLoading: false,
      current: 0,
      localImages: [],
      imageLoading: false,
    };
  },
  mounted() {
    this.fetchPublications().then(() => {
      this.selectPublication(0);
    });
  },
  methods: {
    async selectPublication(index) {
      return new Promise((resolve, reject) => {
        this.current = index;
        DashboardServices.fetchPublicationImages(
          this.publications[index].id
        ).then((data) => {
          this.localImages = data;
        });
      });
    },
    async fetchPublications() {
      return new Promise((resolve, reject) => {
        DashboardServices.fetchPublications().then((data) => {
          this.publications = data;
          resolve();
        });
      });
    },
    addPublication() {
      this.addLoading = true;
      return new Promise((resolve, reject) => {
        DashboardServices.addPublication()
          .then((data) => {
            this.fetchPublications()
              .then(() => {
                this.selectPublication(this.publications.length - 1);
                this.addLoading = false;
              })
              .catch((error) => {
                console.error("Error fething publications: ", error);
                this.addLoading = false;
              });
          })
          .catch((error) => {
            console.error("Error updating document: ", error);
            this.addLoading = false;
          });
      });
    },
    updatePublication() {
      this.updateLoading = true;
      firebase
        .firestore()
        .collection("publications")
        .doc(this.publications[this.current].id)
        .update(this.publications[this.current].data)
        .then(() => {
          this.fetchPublications()
            .then(() => {
              this.updateLoading = false;
            })
            .catch((error) => {
              this.updateLoading = false;
            });
        })
        .catch((error) => {
          this.updateLoading = false;
          console.error("Error updating document: ", error);
        });
    },
    async deleteImg(index) {
      return new Promise((resolve, reject) => {
        DashboardServices.deleteImage(this.localImages[index].ref).then(() => {
          DashboardServices.fetchPublicationImages(
            this.publications[this.current].id
          )
            .then((data) => {
              this.localImages = data;
              resolve();
            })
            .catch((error) => {
              console.log(error);
              reject();
            });
        });
      });
    },
    async deleteAllImages(id) {
      return new Promise((resolve, reject) => {
        firebase
          .storage()
          .ref(id)
          .listAll()
          .then(async (res) => {
            await res.items.forEach(function (itemRef) {
              DashboardServices.deleteImage(itemRef).catch((error) => {
                console.log(error);
                reject();
              });
            });
            resolve();
          });
      });
    },
    async deletePublication() {
      this.deleteLoading = true;
      return new Promise((resolve, reject) => {
        this.deleteAllImages(this.publications[this.current].id).then(() => {
          firebase
            .firestore()
            .collection("publications")
            .doc(this.publications[this.current].id)
            .delete()
            .then(() => {
              this.fetchPublications()
                .then(() => {
                  this.deleteDialog = false;
                  this.deleteLoading = false;
                  this.selectPublication(0).then(() => {
                    resolve();
                  });
                })
                .catch((error) => {
                  this.deleteLoading = false;
                  console.error("Error fetching publications: ", error);
                  reject();
                });
            })
            .catch((error) => {
              this.deleteLoading = false;
              console.error("Error deleting document: ", error);
              reject();
            });
        });
      });
    },
    onFileChanged(e) {
      this.imageLoading = true;
      DashboardServices.addImage(
        this.publications[this.current].id,
        e.target.files[0]
      )
        .then(() => {
          DashboardServices.fetchPublicationImages(
            this.publications[this.current].id
          )
            .then((data) => {
              this.localImages = data;
              this.imageLoading = false;
            })
            .catch(() => (this.imageLoading = false));
        })
        .catch(() => (this.imageLoading = false));
    },
    signOut() {
      AuthenticationServices.signOut().then(() => {
        router.replace("/signin");
      });
    },
  },
  computed: {
    ...mapGetters("authentication", ["user"]),
  },
};
</script>

<style scoped></style>