<template>
	<v-row justify="center" class="ma-0 mb-12 pa-0">
		<v-card elevation="3" width="850" class="pa-6">
			<v-overlay absolute :value="publication.data.grey"></v-overlay>
			<v-row class="ma-0 mb-4">
				<p class="headline font-weight-bold ma-0 pa-0">
					{{ publication.data.nb }}
				</p>
				<v-divider vertical class="mx-6"></v-divider>
				<h2 class="headline font-weight-bold ma-0 pa-0">
					{{ publication.data.title }}
				</h2>
			</v-row>
			<v-row class="ma-0 pa-0">
				<v-divider vertical class="mx-6"></v-divider>
			</v-row>
			<v-row class="ma-0 pa-0">
				<v-col cols="6" class="ma-0 pa-0">
          <v-row class="ma-0 pa-0">
            <v-chip
              v-if="
                !publication.data.grey &&
                publication.data.availabilityDate &&
                publication.data.availabilityDate != ''
              "
              ><p class="ma-0 pa-0 font-weight-bold">
                Disponible le
                {{ publication.data.availabilityDate }}
              </p></v-chip
            >
            <v-chip v-else class="font-weight-bold">Occupé</v-chip>
          </v-row>
				</v-col>
				<v-col cols="6" class="ma-0 pa-0 pl-3">
					<v-row class="ma-0 pa-0">
						<v-chip v-if="publication.data.furnished" class="ml-2"
							>Meublé</v-chip
						>
            <v-chip v-else class="ml-2"
							>Non Meublé</v-chip
						>
            <v-chip v-if="publication.data.internet" class="ml-2"
							>Wifi</v-chip
						>
						<v-chip v-else class="ml-2"
							>Pas de Wifi</v-chip
						>
					</v-row>
				</v-col>
			</v-row>
			<v-divider class="mt-4"></v-divider>
			<v-row class="ma-0 mt-6 pa-0">
				<v-col cols="6" class="ma-0 pa-0 pr-3">
					<v-row class="ma-0 pa-0">
						<v-carousel
							hide-delimiters
							height="250"
							style="border-radius: 8px"
						>
							<v-carousel-item
								v-for="(item, i) in localImages"
								:key="i"
								:src="item.url"
								reverse-transition="fade-transition"
								transition="fade-transition"
							></v-carousel-item>
						</v-carousel>
					</v-row>
				</v-col>
				<v-col cols="6" class="ma-0 pa-0 pl-3">
					<v-row justify="space-between" class="ma-0 pa-0">
						<p class="font-weight-bold ma-0 pa-0 pb-3 px-3">
							Loyer charges comprises
						</p>
						<p class="font-weight-bold ma-0 pa-0 pb-3 px-3">
							{{ publication.data.rent || "0" }} €
						</p>
					</v-row>
					<v-divider></v-divider>
					<v-row justify="space-between" class="ma-0 pa-0">
						<p class="ma-0 pa-0 py-2 px-3">Eau</p>
						<p class="ma-0 pa-0 py-2 px-3">
							{{
								publication.data.waterChargesIncluded
									? "compris"
									: "selon consommation"
							}}
						</p>
					</v-row>
					<v-divider></v-divider>
					<v-row justify="space-between" class="ma-0 pa-0">
						<p class="ma-0 pa-0 py-2 px-3">Électricité</p>
						<p class="ma-0 pa-0 py-2 px-3">
							{{
								publication.data.electricityChargesIncluded
									? "compris"
									: "selon consommation"
							}}
						</p>
					</v-row>
					<v-divider></v-divider>
					<v-row justify="space-between" class="ma-0 pa-0">
						<p class="ma-0 pa-0 py-2 px-3">Chauffage</p>
						<p class="ma-0 pa-0 py-2 px-3">
							{{
								publication.data.heatChargesIncluded
									? "compris"
									: "selon consommation"
							}}
						</p>
					</v-row>
					<v-divider></v-divider>
					<v-row justify="space-between" class="ma-0 pa-0">
						<p class="ma-0 pa-0 py-2 px-3">Wifi</p>
						<p class="ma-0 pa-0 py-2 px-3">
							{{
								publication.data.wifiChargesIncluded
									? "compris"
									: "selon consommation"
							}}
						</p>
					</v-row>
					<v-divider></v-divider>
					<v-row justify="space-between" class="ma-0 pa-0">
						<p class="font-weight-bold ma-0 pa-0 py-3 px-3">
							Frais de copropriété
						</p>
						<p class="font-weight-bold ma-0 pa-0 py-3 px-3">
							{{ publication.data.coOwnershipFees || "0" }} €
						</p>
					</v-row>
				</v-col>
			</v-row>
			<v-row class="ma-0 mt-6 pa-0">
				<pre
					class="ma-0 pa-0 font-weight-light"
					style="font-family: unset"
					>{{ publication.data.description }}</pre
				>
			</v-row>
			<v-divider class="my-4"></v-divider>
			<v-row class="ma-0 pa-0">
				<v-col class="ma-0 pa-0">
					<v-row class="ma-0 pa-0">
						<v-icon size="20">mdi-alert-circle</v-icon>
						<p class="font-weight-light ma-0 ml-2 pa-0">
							{{ publication.data.infos }}
						</p>
					</v-row>
					<v-row class="ma-0 mt-2 pa-0">
						<v-icon size="20">mdi-account</v-icon>
						<p class="font-weight-bold ma-0 ml-2 pa-0">
							{{ publication.data.owner }}
						</p>
					</v-row>
					<v-row class="ma-0 mt-2 pa-0">
						<v-icon size="20">mdi-at</v-icon>
						<a
							@click="
								$router.push(
									'/contact?dest=' + publication.data.email + '&owner=' + publication.data.owner
								)
							"
							class="font-weight-bold ma-0 ml-2 pa-0 black--text"
						>
							{{ publication.data.email }}
						</a>
					</v-row>
					<v-row class="ma-0 mt-2 pa-0" v-if="!publication.data.grey">
						<v-icon size="20">mdi-phone</v-icon>
						<p
							v-if="publication.data.phone1"
							class="font-weight-bold ma-0 ml-2 pa-0"
						>
							{{ publication.data.phone1 }}
						</p>
						<p
							v-if="publication.data.phone2"
							class="font-weight-bold ma-0 ml-2 pa-0"
						>
							- {{ publication.data.phone2 }}
						</p>
					</v-row>
				</v-col>
			</v-row>
		</v-card>
	</v-row>
</template>

<script>
import { DashboardServices } from "@/services/dashboardServices";
import firebase from "firebase";

export default {
	props: ["publication"],
	mounted() {
		this.fetchPublicationImages();
	},
	data() {
		return {
			localImages: [],
		};
	},
	methods: {
		fetchPublicationImages() {
			DashboardServices.fetchPublicationImages(this.publication.id).then(
				(data) => {
					this.localImages = data;
				}
			);
		},
	},
};
</script>

<style scoped>
</style>