<template>
  <v-container fill-height fluid class="ma-0 pa-0" style="align-items: center">
    <v-row justify="center" class="ma-0 mt-12 pa-0">
      <h1>Se connecter</h1>
    </v-row>
    <v-row justify="center" class="ma-0 mt-8 pa-0">
      <v-card width="450" class="ma-0 mb-12 pa-6">
        <v-text-field label="Adresse email" v-model="email"></v-text-field>
        <v-text-field label="Mot de passe" type="password" v-model="password"></v-text-field>
        <p class="ma-0 mb-4 pa-0 error--text">{{ errorMessage }}</p>
        <v-btn :loading="loading" class="mt-2" @click="signIn">Valider</v-btn>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import firebase from "firebase";
import router from '@/router';
import store from '@/store';
import { mapGetters } from "vuex";
import { AuthenticationServices } from '@/services/authenticationServices';

export default {
  data() {
    return {
      email: "",
      password: "",
      loading: false,
      errorMessage: "",
    };
  },
  mounted() {
    if (this.user.loggedIn) {
      router.replace('/dashboard');
    }
  },
  methods: {
    signIn() {
      this.loading = true;
      AuthenticationServices.signIn(this.email, this.password)
        .then(() => {
          this.loading = false;
          router.replace('/dashboard');
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        })
    }
  },
  computed: {
    ...mapGetters("authentication", ["user"]),
  }
};
</script>

<style scoped></style>