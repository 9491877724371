<template>
  <div id="app">
    <div class="spacer"></div>
    <slot />
  </div>
</template>

<script>
export default {
};
</script>

<style></style>
