import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "@/store";
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import Publications from "../views/Publications.vue";
import Contact from "../views/Contact.vue";
import SignIn from "../views/SignIn.vue";
import Dashboard from "../views/Dashboard.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/about",
    name: "About",
    component: About
  },
  {
    path: "/publications",
    name: "Publications",
    component: Publications
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact
  },
  {
    path: "/signin",
    name: "SignIn",
    meta: { layout: "dashboard", title: "SignIn" },
    component: SignIn
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    meta: { layout: "dashboard", title: "Dashboard" },
    component: Dashboard
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  window.document.title = to.meta && to.meta.title ? 'Hebergement Jeunes - ' + to.meta.title : 'Hebergement Jeunes';
  const privatePages = [
    "/dashboard",
  ];
  const authRequired = privatePages.includes(to.path);
  return authRequired && !store.getters['authentication/loggedIn'] ? next({ path: "/signin" }) : next();
});

export default router;
