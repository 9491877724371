import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import firebase from 'firebase';
import DefaultLayout from "./layouts/DefaultLayout.vue";
import DashboardLayout from "./layouts/DashboardLayout.vue";

Vue.component("default-layout", DefaultLayout);
Vue.component("dashboard-layout", DashboardLayout);

Vue.config.productionTip = false;

const firebaseConfig = {
  apiKey: "AIzaSyAHkTrgOT0SsiNpd2RLCZyn23hziH5A9Og",
  authDomain: "hebergementjeunes-ab7f2.firebaseapp.com",
  projectId: "hebergementjeunes-ab7f2",
  storageBucket: "hebergementjeunes-ab7f2.appspot.com",
  messagingSenderId: "1029888408702",
  appId: "1:1029888408702:web:464f5adf82154d67372c2f"
};

firebase.initializeApp(firebaseConfig);

firebase.auth().onAuthStateChanged(user => {
  store.dispatch("authentication/fetchUser", user);
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
