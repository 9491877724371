import Vue from "vue";
import Vuex from "vuex";
import authentication from './modules/authentication';
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const store = new Vuex.Store({
  plugins: [createPersistedState(
    {
      paths: ['authentication'],
    }
  )],
  modules: {
    authentication,
  },
  strict: true
});

export default store;
