<template>
  <v-row
    align="center"
    class="ma-0 pa-0 py-2"
    justify="space-between"
    style="border-bottom: 1px solid #00000030"
  >
    <v-col class="ma-0 pa-0">
      <v-card @click="selectPublication(index)" flat class="ma-0 pa-0 pl-4" :color="current ? 'primary' : 'white'">
        <v-row align="center" justify="start" class="ma-0 pa-0 py-3 pl-2">
          <p :style="current ? 'color: white' : 'black'" class="font-weight-bold ma-0 pa-0">{{ publication.nb }}</p>
          <p :style="current ? 'color: white' : 'black'" class="col-9 ma-0 ml-4 pa-0 text-truncate">
            {{ publication.owner || "Nouveau" }}
          </p>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import firebase from 'firebase';

export default {
  props: [
    'publication',
    'index',
    'current',
    'selectPublication',
  ],
};
</script>

<style scoped></style>