<template>
  <v-container fluid class="ma-0 my-12 pa-0" style="align-items: start">
    <v-row align="center" justify="center" class="ma-0 pa-0">
      <v-col xs="10" sm="10" md="8" lg="7" xl="7" class="ma-0 pa-0">
        <v-row align="center" justify="space-between" class="ma-0 mb-8 pa-0">
          <h1 class="ma-0 pa-0">Logements proposés</h1>
          <v-col cols="3" class="ma-0 pa-0">
            <v-select @change="fetchPublications" v-model="filter" :items="['Disponibles', 'Tous']" label="Filtre"></v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="filteredPublications.length == 0" class="ma-0 pa-0">
      <v-col xs="10" sm="10" md="8" lg="7" xl="7" class="ma-0 pa-0">
        <v-skeleton-loader
          v-for="i in 5"
          :key="i"
          class="mx-auto mb-12"
          max-width="850"
          type="card"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row justify="center" v-else class="ma-0 pa-0">
      <v-col xs="10" sm="10" md="8" lg="7" xl="7" class="ma-0 pa-0">
        <publication-card
          v-for="(publication, i) in filteredPublications"
          :key="i"
          :publication="publication"
        ></publication-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from "firebase";
import PublicationCard from "@/components/PublicationCard.vue";
import { DashboardServices } from "@/services/dashboardServices";

export default {
  components: {
    "publication-card": PublicationCard,
  },
  data() {
    return {
      publications: [],
      localImages: [],
      filter: 'Disponibles',
    };
  },
  mounted() {
    this.fetchPublications();
  },
  methods: {
    async fetchPublications() {
      this.publications = [];
      return new Promise((resolve, reject) => {
        DashboardServices.fetchPublications().then((data) => {
          this.publications = data;
          resolve();
        });
      });
    },
  },
  computed: {
    filteredPublications() {
      return this.publications.filter((publication) => !publication.data.hidden && (this.filter == 'Tous' || !publication.data.grey));
    }
  }
};
</script>
