import firebase from 'firebase';

async function fetchPublications() {
  return new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection("publications")
      .orderBy("creationDate")
      .get()
      .then((querySnapshot) => {
        const publications: any = [];
        querySnapshot.forEach((doc) => {
          publications.push({ id: doc.id, data: doc.data() });
        });
        resolve(publications);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      })
  });
}

async function fetchPublicationImages(id: string) {
  return new Promise((resolve, reject) => {
    const listRef = firebase.storage().ref(id);
    listRef.listAll().then(function (res) {
      const images: any = [];
      res.items.forEach(function (itemRef) {
        itemRef
          .getDownloadURL()
          .then((url) => {
            images.push({url: url, ref: itemRef});
          })
          .catch((error) => {
            console.log("Error while downloading image: " + error);
            reject(error);
          });
      });
      resolve(images);
    }).catch(function (error) {
      console.log(error);
      reject(error);
    });
  });
}

async function addImage(id: string, image: File) {
  return new Promise<void>((resolve, reject) => {
    firebase
      .storage()
      .ref()
      .child(id + "/" + image.name)
      .put(image)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        console.error("Error uploading document: ", error);
        reject(error);
      });
  })
}

async function deleteImage(ref: any) {
  return new Promise<void>((resolve, reject) => {
    firebase.storage().ref().child(ref.fullPath).delete().then(function () {
      resolve();
    }).catch((error: any) => {
      console.error("Error deleting file: ", error);
      reject(error);
    });
  })
}

async function addPublication() {
  return new Promise<void>((resolve, reject) => {
    firebase
      .firestore()
      .collection("publications")
      .add({
        nb: 0,
        creationDate: Date.now(),
        hidden: false,
        grey: false,
      })
      .then(() => {
        resolve();
      })
      .catch(error => {
        console.error("Error updating document: ", error);
        reject(error);
      });
  })
}

export const DashboardServices = {
  fetchPublications,
  fetchPublicationImages,
  addPublication,
  addImage,
  deleteImage,
};
