<template>
  <v-container
    fluid
    class="ma-0 mt-4 mb-12 pa-0"
    style="align-items: start"
  >
    <v-row justify="center" class="ma-0 pa-0">
      <v-col xs="10" sm="10" md="8" lg="7" xl="7" class="ma-0 pa-0">
        <v-row justify="center" class="ma-0 mt-8 pa-0">
          <h1>
            L'association "Allo Hébergement Jeunes" propose
          </h1>
        </v-row>
        <v-row justify="center" class="ma-0 mt-10 pa-0">
          <v-btn height="42" color="white" to="/publications">Voir la liste des logements</v-btn>
        </v-row>
        <v-row class="ma-0 mt-12 pa-0">
          <v-col class="ma-0 pa-0">
            <v-card class="ma-0 pa-5" style="border-radius: 12px">
              <v-row align="center" class="ma-0 pa-0">
                <v-avatar size="86" style="border: 6px solid #2ecc71">
                  <v-icon size="32">mdi-check-decagram</v-icon>
                </v-avatar>
                <v-col class="ma-0 ml-5 pa-0">
                  <h3 class="headline font-weight-medium ma-0 pa-0">
                    Des logements adaptés
                  </h3>
                  <p class="body-1 ma-0 mt-1 pa-0">
                    Pour les étudiants, apprentis, stagiaires et jeunes
                    travailleurs en alternance.
                  </p>
                </v-col>
              </v-row>
            </v-card>
            <v-card class="ma-0 mt-12 pa-5" style="border-radius: 12px">
              <v-row align="center" class="ma-0 pa-0">
                <v-col class="ma-0 pa-0">
                  <h3
                    class="headline font-weight-medium ma-0 pa-0"
                    style="text-align: end"
                  >
                    Une mise en relation aisée
                  </h3>
                  <p class="body-1 ma-0 mt-1 pa-0" style="text-align: end">
                    Pour plus de simplicité nous vous fournissons les adresses
                    mails et numéros des propriétaires. En cas de soucis, nous
                    sommes à votre disposition pour vous aider dans vos
                    démarches.
                  </p>
                </v-col>
                <v-avatar
                  size="86"
                  class="ml-5"
                  style="border: 6px solid #2ecc71"
                >
                  <v-icon size="32">mdi-forum</v-icon>
                </v-avatar>
              </v-row>
            </v-card>
            <v-card class="ma-0 mt-12 pa-5" style="border-radius: 12px">
              <v-row align="center" class="ma-0 pa-0">
                <v-avatar size="86" style="border: 6px solid #2ecc71">
                  <v-icon size="32">mdi-currency-eur-off</v-icon>
                </v-avatar>
                <v-col class="ma-0 ml-5 pa-0">
                  <h3 class="headline font-weight-medium ma-0 pa-0">
                    Des logements peu couteux
                  </h3>
                  <p class="body-1 ma-0 mt-1 pa-0">
                    Vous êtes jeune, étudiant ou vous faites votre entrée dans
                    la vie active et vous souhaitez vous loger pour un coût
                    raisonnable. Nous vous proposons des solutions
                    économiquement intéressantes.
                  </p>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-sheet height="50" color="transparent"></v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
};
</script>
