<template>
  <v-row justify="center" class="ma-0 my-8 pa-0">
    <v-col xs="10" sm="10" md="8" lg="7" xl="7" class="ma-0 pa-0">
      <v-card flat class="ma-0 pa-4" style="align-items: start">
        <v-row justify="center" class="ma-0 pa-0">
          <h1>"Allo hébergement jeunes"</h1>
        </v-row>
        <p class="mt-8">
          L’Association « Allo Hébergement Jeunes » a été créée en 1987 à la
          suite d’un constat : la difficulté pour de nombreux jeunes à se loger
          sur Challans. L’idée germe alors dans la tête de quelques bénévoles,
          dont certains ont des chambres ou studios disponibles.
        </p>
        <p>
          Depuis bientôt 30 ans, nous proposons donc des logements aux
          étudiants, apprentis, stagiaires, saisonniers ou jeunes travailleurs en alternance.
        </p>
        <p>Comment ça marche ?</p>
        <p>
          L’association dispose d’une liste actualisée d’offre de logements et
          la met à disposition gratuitement sur le site Web de la ville de
          Challans à la rubrique « VIVRE ICI » puis « logements privés ».
        </p>
        <p>
          Charge au jeune, intéressé par une annonce, de prendre directement
          contact avec le propriétaire pour convenir des modalités de la
          location.
        </p>
        <p>
          Les prix des loyers sont fixés librement par les propriétaires qui se
          doivent de rédiger un contrat de location.
        </p>
        <p>
          Les offres recueillies par l’association vont généralement de 250 € à
          370 € par mois. Ce sont des offres de logements classiques, qui vont
          de la chambre meublée au studio, appartement voire maison en
          colocation. A noter que les propriétaires demandent souvent un garant
          et exigent une caution représentant un mois de loyer.
        </p>
        <p>
          Les durées de location correspondent généralement à la période
          scolaire (sept-juin), mais peuvent parfois être adaptées à des
          périodes plus courtes, correspondant à un stage par exemple, ou plus
          longues lorsqu’il s’agit d'un contrat d’apprentissage ou d'une alternance (école - entreprise).
        </p>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {};
</script>
