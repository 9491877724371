import firebase from 'firebase';
import router from '@/router';

async function signIn(email: string, password: string) {
  return new Promise((resolve, reject) => {
    firebase.auth().signInWithEmailAndPassword(email, password)
      .then(() => {
        resolve();
      })
      .catch(err => {
        reject(err);
      });

  });
}

async function signOut(email: string, password: string) {
  return new Promise((resolve) => {
    firebase.auth().signOut()
      .then(() => {
        resolve();
      })
  });
}

export const AuthenticationServices = {
  signIn,
  signOut
};
