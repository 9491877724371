<template>
	<div id="app">
		<nav class="ma-0 pa-0">
			<v-app-bar
				app
				height="86px"
				fixed
				flat
				class="grey lighten-2"
				src="@/assets/background.jpg"
				style="
					z-index: 100;
					border-bottom: 6px solid #d74f51 !important;
				"
			>
				<v-avatar
					class="ma-0 pa-0 hidden-md-and-up"
					style="margin-top: -10px !important"
				>
					<v-img contain src="@/assets/logo.png"></v-img>
				</v-avatar>
				<h1>
					<span
						class="ma-0 pa-0 hidden-sm-and-down"
						style="color: #2ecc71"
						>Hébergements Jeunes</span
					>
					<span class="ma-0 ml-2 pa-0 primary--text hidden-sm-and-down">Challans</span>
				</h1>
				<v-spacer></v-spacer>
				<v-btn
					text
					class="subtitle-1 font-weight-medium"
					color="white"
					to="/"
					>Accueil</v-btn
				>
				<v-btn
					text
					class="subtitle-1 font-weight-medium"
					color="white"
					to="/about"
					>L'association</v-btn
				>
				<v-btn
					text
					class="subtitle-1 font-weight-medium"
					color="white"
					to="/publications"
					>Logements</v-btn
				>
				<v-btn
					text
					class="subtitle-1 font-weight-medium"
					color="white"
					to="/contact"
					>Contact</v-btn
				>
			</v-app-bar>
		</nav>
		<div class="spacer"></div>
		<v-main app>
			<slot />
			<v-sheet height="220"></v-sheet>
		</v-main>
		<v-footer class="ma-0 pa-0">
			<v-container
				fluid
				class="ma-0 pa-0"
				style="background-color: #202020; position: absolute; bottom: 0"
			>
				<v-row align="center" class="ma-0 pa-0 py-6">
					<v-img
						max-height="132"
						max-width="200"
						contain
						src="@/assets/logo.png"
					></v-img>
					<v-col class="ma-0 ml-6 mt-4 pa-0">
						<p class="white--text ma-0 pa-0 font-weight-bold">
							Contact BUREAU
						</p>
						<v-row class="ma-0 mt-2 pa-0">
							<a
								@click="$router.push('/contact')"
								class="white--text font-weight-bold ma-0 pa-0"
								>hebergementjeunes@free.fr</a
							>
						</v-row>
					</v-col>
				</v-row>
				<v-row
					align="center"
					justify="center"
					class="ma-0 pa-0"
					style="background-color: black"
				>
					<p class="white--text ma-0 pa-0 py-3">
						Made by
						<a
							href="https://www.linkedin.com/in/simongalasso/"
							target="_blank"
							><strong>Simon</strong></a
						>
					</p>
				</v-row>
			</v-container>
		</v-footer>
	</div>
</template>

<script>
export default {};
</script>

<style></style>
