import Vue from "vue";
import Vuetify from "vuetify";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#d74f51",
        secondary: "#2ecc71"
      },
    },
  },
});
